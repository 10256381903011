import { type Languages } from '@/constants'
import { useLocalStorage } from '@/hooks'
import { useCookie } from '@/hooks/useCookie'
import { useCookie1Y } from '@/hooks/useCookie1Y'
import {
  type BannerList,
  type Keywords,
  type Menu,
  type ProductCompare,
  type ProductViewed,
  type Province,
} from '@/types'
import constate from 'constate'
import { useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

type Props = {
  stores: Province[] | null
  area: string | null
  language: Languages
  menus: Menu[] | null
  quickMenu: Menu[] | null
  categoryMenu: Menu[] | null
  banner: BannerList[] | null
  keywords: Keywords[] | null
  bottomMenu: Menu[] | null
}

const defaultProvince = '01'
const StoreContainer = (props: Props) => {
  const [isOpenPopupLocation, setIsOpenPopupLocation] = useState(false)
  const [isOpenComparePopup, setIsOpenComparePopup] = useState(false)
  const [isOpenAddComparePopup, setIsOpenAddComparePopup] = useState(false)
  const [catViewId, setCatViewId] = useState<string | null>(null)
  const [bottomMenu] = useState<Menu[]>(props.bottomMenu || [])
  const [productCompares, setProductCompares] = useLocalStorageState<
    ProductCompare[]
  >('productCompare', { defaultValue: [{}, {}, {}] })

  const [productViewed, setProductViewed] = useLocalStorage<ProductViewed[]>(
    'productViewed',
    []
  )

  const [isDisplayAreaPopup, setIsDisplayAreaPopup] = useLocalStorage<boolean>(
    'areaPopup',
    true
  )
  const [stores, setStores] = useState<Province[]>([])
  const [megaMenu, setMegaMenu] = useState<Menu[]>(props.menus || [])
  const [categoryMenu, setCategoryMenu] = useState<Menu[]>(
    props.categoryMenu || []
  )
  const [quickMenu, setQuickMenu] = useState<Menu[]>(props.quickMenu || [])
  const [banner, setBanner] = useState<BannerList[]>(props.banner || [])
  const [province, setProvince] = useCookie<string | null>('province', null)
  const [selectedLang, setSelectedLang] = useCookie<
    Languages | undefined | null
  >('language', props.language)
  const [destinationCode, setDestinationCode] = useCookie<string | null>(
    'destinationCode',
    null
  )
  const [area, setArea] = useCookie1Y<string | null>('area', null)
  const [areaId, setAreaId] = useCookie1Y<string | null>('area-id', null)
  const [keywords, setKeywords] = useState<Keywords[]>(props.keywords || [])

  useEffect(() => {
    if (props.keywords && keywords.length == 0) {
      setKeywords(props.keywords)
    }
  }, [props.keywords])
  useEffect(() => {
    if (stores) {
      const defaultStore = stores.find(e => e.def || e.code === defaultProvince)
      if (!area && !areaId && defaultStore) {
        setArea(defaultStore?.parentCode)
        setAreaId(defaultStore?.parentId)
      }

      if (defaultStore && !province) {
        setProvince(defaultStore?.code)
        setArea(defaultStore?.parentCode)
        setAreaId(defaultStore?.parentId)
      }
    }
  }, [stores, area])

  useEffect(() => {
    if (props.menus) {
      setMegaMenu(props.menus)
    }
  }, [props.menus])
  useEffect(() => {
    if (props.categoryMenu) {
      setCategoryMenu(props.categoryMenu)
    }
  }, [props.categoryMenu])

  useEffect(() => {
    if (props.quickMenu && !quickMenu) {
      setQuickMenu(props.quickMenu)
    }
  }, [props.quickMenu])

  useEffect(() => {
    if (props.banner) {
      setBanner(props.banner)
    }
  }, [props.banner])

  useEffect(() => {
    if (props.stores) {
      setStores(props.stores)
    }
  }, [props.stores])

  useEffect(() => {
    if (props.language && !selectedLang) {
      setSelectedLang(props.language)
    }
  }, [props.language])

  return {
    province,
    setProvince,
    area,
    areaId,
    setArea,
    setAreaId,
    setSelectedLang,
    selectedLang,
    stores,
    banner,
    megaMenu,
    quickMenu,
    categoryMenu,
    setStores,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
    isDisplayAreaPopup,
    setIsDisplayAreaPopup,
    setDestinationCode,
    destinationCode,
    productCompares,
    setProductCompares,
    isOpenComparePopup,
    setIsOpenComparePopup,
    isOpenAddComparePopup,
    setIsOpenAddComparePopup,
    productViewed,
    setProductViewed,
    catViewId,
    setCatViewId,
    keywords,
    bottomMenu,
  }
}

export const [StoreProvider, useStore] = constate(StoreContainer)
