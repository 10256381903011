/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DEFAULT_REGION,
  Languages,
  LocalStorageKeys,
  Platform,
  SaleChannel,
} from '@/constants'
import type {
  InternalAxiosRequestConfig as AxiosConfig,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'

let context = <Record<string, string>>{}
interface AxiosMetadaInterface extends AxiosRequestConfig {
  metadata: any
}
type ContextOverride = { language?: string | null; channel?: string | null }
export const setContext = async (_context: any, override?: ContextOverride) => {
  context = _context
  if (override && override.language) {
    if (context.language) {
      override.language = context.language
    } else {
      context = {
        ...context,
        ...{ language: override.language },
      }
    }
  }

  if (override && override.channel) {
    context = {
      ...context,
      ...{ channel: override.channel },
    }
  }
  context.messages = (
    await import(`../../messages/${context.language || Languages.VN}.json`)
  ).default
  return context
}

export const getTranslate = (
  data?: Record<string, string> | Record<string, string[]> | any,
  lang?: Languages | null
) => {
  if (!data) return ''
  if (lang) {
    return data[lang]
  }
  const browserLang = getCookie('language')
  if (!isServer && browserLang) {
    if (data[browserLang as string]) {
      return data[browserLang as string]
    }
    return data[Languages.VN]
  }
  if (data[context.language]) {
    return data[context.language]
  }
  return data[Languages.VN]
}

export const isServer = typeof window === 'undefined'

function onSuccessfulRequest(config: AxiosConfig<any>): AxiosConfig<any> {
  const token = getCookie('token')
  const app = getCookie('app')
  const language = getCookie('language')
  const area = getCookie('area')
  const guestId = getCookie('guestId')
  const channel = getCookie('channel')
  const destinationCode = getCookie('destinationCode')
  const accessToken = getCookie('access')
  const isNewVersion =
    getCookie(LocalStorageKeys.VERSION) !==
    process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION
  if (isNewVersion) {
    deleteCookie('province')
    deleteCookie('area-id')
    deleteCookie('channel')
    deleteCookie('area')
    deleteCookie('access')
    setCookie('access', process.env.NEXT_PUBLIC_ACCESS_TOKEN, {
      maxAge: 2147483647,
    })
    setCookie(
      'channel',
      app && app == 'b2e' ? SaleChannel.B2E : SaleChannel.B2C,
      {
        maxAge: 2147483647,
      }
    )
    setCookie('area', DEFAULT_REGION, {
      maxAge: 2147483647,
    })
    setCookie('language', Languages.VN, {
      maxAge: 2147483647,
    })
    setCookie(
      LocalStorageKeys.VERSION,
      process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION,
      {
        maxAge: 2147483647,
      }
    )
  }

  config.headers['Party'] = 'ecom'
  config.headers['Platform'] = Platform.MOBILE
  config.headers['Channel'] =
    app && app == 'b2e' ? SaleChannel.B2E : SaleChannel.B2C
  ;(config as AxiosMetadaInterface).metadata = { startTime: new Date() }
  // Client
  if (config.headers && !isServer) {
    config.headers['Accept-Language'] = language || Languages.VN
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (area) config.headers['Region-code'] = area || DEFAULT_REGION
    if (guestId) config.headers['Uuid'] = guestId
    if (channel) config.headers['Channel'] = channel
    if (destinationCode) config.headers['destination-code'] = destinationCode
    if (accessToken) config.headers['Access'] = accessToken
    return config
  }

  // Server
  if (context) {
    const { token, language, area, channel, destinationCode } = context
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (language) config.headers['Accept-language'] = language
    if (channel)
      config.headers['Channel'] =
        app && app == SaleChannel.B2E ? SaleChannel.B2E : channel
    if (destinationCode) config.headers['destination-code'] = destinationCode
    if (accessToken) config.headers['Access'] = accessToken
    config.headers['Region-code'] = area || DEFAULT_REGION
  }
  return config
}

async function onFailedRequest(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}
function onSuccessfulResponse(res: AxiosResponse<any>): AxiosResponse<any> {
  return res
}

async function onFailedResponse(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}

export const applyInterceptors = (instance: AxiosInstance): void => {
  instance.interceptors.request.use(onSuccessfulRequest, onFailedRequest)
  instance.interceptors.response.use(onSuccessfulResponse, onFailedResponse)
}
