import type { SystemConfig } from '@/types'
import { api } from '@/utils'
import constate from 'constate'
import NProgress from 'nprogress'
import { useEffect, useState } from 'react'

const Container = () => {
  const [notification, setNotification] = useState({ type: '', message: '' })
  const [systemConfig, setSystemConfig] = useState<SystemConfig>()
  const showNotification = (message: string, type: string) => {
    setNotification({ type, message })
    setTimeout(() => {
      setNotification({ type, message: '' })
    }, 4000)
  }
  useEffect(() => {
    if (!systemConfig) {
      api(`api/configuration`, {
        baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
        method: 'get',
      })
        .then(res => {
          if (res && !(res instanceof Error)) {
            setSystemConfig(res.data)
          }
        })
        .catch(() => null)
    }
  }, [])
  const showProgressBar = () => {
    NProgress.remove()
    NProgress.start()
  }

  const hideProgressBar = () => {
    NProgress.done()
  }

  return {
    systemConfig,
    notification,
    setNotification,
    showNotification,
    showProgressBar,
    hideProgressBar,
  }
}

export const [NotificationProvider, useNotification] = constate(Container)
