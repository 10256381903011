/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { type AxiosRequestConfig } from 'axios'
import { applyInterceptors } from './api-interceptors'

export type RequestConfig = {
  retry?: number
  retryDelay?: number
} & AxiosRequestConfig

enum HTTP_ERROR_CODE {
  BAD_REQUEST = 'ERR_BAD_REQUEST',
}

class CustomError extends Error {
  public statusCode: string
  constructor(message: string, statusCode?: string) {
    super(message)
    this.statusCode = statusCode || ''
  }
}

export const constructErrorMessage = (err: any) => {
  if (err.response) {
    const { validators, message } = err.response.data
    if (validators && Array.isArray(validators)) {
      const errors = validators
        .reduce((acc: any, cur: any) => {
          const keys = Object.keys(cur)
          const { ...identifier } = cur[keys[0]]
          return [
            ...acc,
            {
              key: keys[0],
              message: Object.values(identifier)[0],
            },
          ]
        }, [])
        .reduce(
          (obj: any, item: any) =>
            Object.assign(obj, { [item.key]: item.message }),
          {}
        )
      return errors
    }
    return message
  }
  return err.message
}

export const api = async (input: string, init: AxiosRequestConfig) => {
  const instance = axios.create({
    baseURL: `${init.baseURL}/${input}`,
    headers: init.headers,
  })
  applyInterceptors(instance)
  const res = await instance(`${init.baseURL}/${input}`, { ...init }).catch(
    (e: Error) => e
  )
  if (res instanceof Error) {
    const { code, response } = res as any
    if (code === HTTP_ERROR_CODE.BAD_REQUEST && response?.data?.message) {
      return new Error((res as any).response.data.message)
    }
    return res
  }

  if (res.data.statusCode) {
    return new CustomError(res.data.message, res.data.statusCode as string)
  }
  if (res.status > 299) {
    return new Error(res.data.message)
  }
  return res.data
}
