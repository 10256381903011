import { AuthProvider, CartProvider, StoreProvider } from '@/containers'
import { FlashSaleProvider } from '@/containers/FlashSaleContainer'
import { NotificationProvider } from '@/containers/NotificationContainer'
import '@/styles/global.css'
import * as fbq from '@/utils/fpixel'
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google'
import { setCookie } from 'cookies-next'
import { NextIntlProvider } from 'next-intl'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
const PopupMain = dynamic(() => import('@/components/PopupMain'), {
  ssr: false,
})
NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})
const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient())
  const router = useRouter()
  useEffect(() => {
    fbq.pageView()
    const handleRouteChange = () => {
      fbq.pageView()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  useEffect(() => {
    if (router.query.app) {
      localStorage?.setItem('app', router?.query?.app as string)
      setCookie('app', router?.query?.app as string, { maxAge: 2147483647 })
      setCookie('channel', router?.query?.app as string, { maxAge: 2147483647 })
    }
  }, [router])
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const version = localStorage?.getItem(LocalStorageKeys.VERSION) || null
  //     if (version !== process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION) {
  //       localStorage?.clear()
  //       localStorage?.setItem(
  //         LocalStorageKeys.VERSION,
  //         process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION as string
  //       )
  //     }
  //   }
  // })

  useEffect(() => {
    const handleStart = () => {
      NProgress.start()
    }
    const handleStop = () => {
      NProgress.done()
    }
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)
    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])
  return (
    <NotificationProvider>
      <img
        src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+'
        alt=''
        width={9999}
        height={9999}
        style={{
          pointerEvents: 'none',
          width: '100vw',
          height: '99vh',
          position: 'absolute',
          left: 0,
          zIndex: -1,
        }}
      />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_TRACKING_ID || ''} />
      <Script src={process.env.NEXT_PUBLIC_CYBER_SOURCE_FORM} />
      <NextIntlProvider messages={pageProps.messages}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider authToken={pageProps.token}>
            <CartProvider>
              <StoreProvider {...pageProps}>
                <FlashSaleProvider {...pageProps}>
                  <DefaultSeo
                    title={
                      pageProps?.systemConfig?.seoTitleDefault ||
                      process.env.NEXT_SEO_TITLE
                    }
                    description={
                      pageProps?.systemConfig?.seoDescriptionDefault ||
                      process.env.NEXT_SEO_DESCRIPTION
                    }
                    canonical={process.env.NEXT_SEO_CANONICAL}
                    openGraph={{
                      url: router.basePath,
                      title:
                        pageProps?.systemConfig?.seoTitleDefault ||
                        process.env.NEXT_SEO_TITLE,
                      description:
                        pageProps?.systemConfig?.seoDescriptionDefault ||
                        process.env.NEXT_SEO_DESCRIPTION,
                      images: [
                        {
                          url:
                            pageProps?.systemConfig?.seoImageDefault ||
                            process.env.NEXT_SEO_IMAGE ||
                            '',
                        },
                      ],
                      site_name: router.basePath,
                      type: 'website',
                      locale: 'vi_IE',
                      siteName: router.basePath,
                    }}
                    additionalMetaTags={[
                      {
                        name: 'keywords',
                        content:
                          pageProps?.systemConfig?.seoKeywordDefault ||
                          process.env.NEXT_SEO_KEYWORD ||
                          '',
                      },
                    ]}
                  />
                  <div className={`root_app font-display scrollbar-hide`}>
                    <Component {...pageProps} />
                  </div>
                  <ToastContainer
                    position='top-center'
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='light'
                  />
                </FlashSaleProvider>
              </StoreProvider>
            </CartProvider>
            <PopupMain />
          </AuthProvider>
        </QueryClientProvider>
      </NextIntlProvider>
    </NotificationProvider>
  )
}
export default App
